import RoutesJSON from '../../json/routes.json';
import CustomLink from '../../components/CustomLink';

const Error = ({ code }) => {
    return (
        <div className="flex flex-col items-center justify-center">
            <img
                src="./images/logos/lvlup-text.svg"
                alt="Logo LVLUP"
                className="h-44 mt-8 mb-16"
            ></img>
            <div>
                <h1 className="text-5xl text-center font-semibold mb-8">
                    Erreur {code}
                </h1>
                <div className="text-xl mb-8">{displayErrorMessage(code)}</div>
            </div>
            <CustomLink link={RoutesJSON.home} text={"Retour à l'accueil."} />
        </div>
    );
};

const displayErrorMessage = (code) => {
    switch (code) {
        case 401:
            return (
                <div>
                    <div>Tu n'as pas les droits pour accéder à cette page,</div>
                    <div>connecte-toi ou crée un compte pour y accéder.</div>
                    <div className="mt-4 text-center">
                        <CustomLink
                            link={RoutesJSON.login}
                            text={'Se connecter ou créer un compte'}
                        />
                    </div>
                </div>
            );
        case 403:
            return <div>Les inscriptions sont désactivées pour le moment.</div>;
        case 404:
            return <div>La page n'a pas été trouvée...</div>;
        default:
            return <div>Erreur inconnue.</div>;
    }
};

export default Error;
