import RoutesJSON from '../../json/routes.json';
import { NavHashLink } from 'react-router-hash-link';
import React, { useState } from 'react';
import { RiMenuFill, RiUserLine, RiCloseFill } from 'react-icons/ri';
import { Link } from 'react-router-dom';

const header = [
    { name: 'À propos', link: RoutesJSON.about },
    { name: 'Jeux', link: RoutesJSON.games },
    { name: 'Contact', link: RoutesJSON.contact },
    { name: 'Règlement', link: RoutesJSON.rules },
];

const Header = () => (
    <header className="bg-white text-lg xl:text-xl">
        <DesktopMenu />
        <MobileMenu />
    </header>
);

export const HomeLink = ({ forceReload = true }) => {
    return (
        <Link
            reloadDocument={forceReload}
            to={RoutesJSON.home}
            className="h-full flex items-center py-2"
        >
            <img
                className={'h-full'}
                src={'./images/logos/lvlup-sm.png'}
                alt={'Logo LVLUP Game Jam'}
            />
        </Link>
    );
};

const DesktopMenu = () => (
    <div className={'hidden 2xl:block'}>
        <nav className={'mx-120 flex h-20 items-center justify-between'}>
            <HomeLink />
            {Object.values(header).map((element, index) => (
                <NavHashLink
                    smooth
                    to={`#${element.link}`}
                    className={'hover:text-blue'}
                    key={index}
                >
                    {element.name}
                </NavHashLink>
            ))}
            {/*<UserButton />*/}
        </nav>
    </div>
);

const UserButton = () => (
    <Link to={RoutesJSON.login}>
        <RiUserLine size={30} className="hover:text-blue" />
    </Link>
);

const MobileMenu = () => {
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
    const toggleMobileMenu = () =>
        setIsMobileMenuOpen((prevState) => !prevState);

    return (
        <div className={'2xl:hidden'}>
            <div className={'flex justify-between px-12 items-center h-20'}>
                <HomeLink />
                <div className={'flex space-x-4'}>
                    {/*<UserButton />*/}
                    <button
                        onClick={toggleMobileMenu}
                        className={'hover:text-blue'}
                    >
                        {!isMobileMenuOpen ? (
                            <RiMenuFill size={30} />
                        ) : (
                            <RiCloseFill size={30} />
                        )}
                    </button>
                </div>
            </div>
            <nav
                className={`flex flex-col space-y-4 pl-12 ${
                    isMobileMenuOpen ? 'block' : 'hidden'
                }`}
                style={{ height: 'calc(100vh)' }}
            >
                {Object.values(header).map((element, index) => (
                    <NavHashLink
                        smooth
                        to={`#${element.link}`}
                        onClick={() => toggleMobileMenu()}
                        className={'hover:text-blue'}
                        key={index}
                    >
                        {element.name}
                    </NavHashLink>
                ))}
            </nav>
        </div>
    );
};

export default Header;
