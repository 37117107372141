import Swal from 'sweetalert2';
const PASSWORD_MIN_LENGTH = 8;
const NAME_MIN_LENGTH = 1;
const DISCORD_MIN_LENGTH = 3; // including @

export const isPasswordValid = (password) =>
    password.trim().length >= PASSWORD_MIN_LENGTH && !/\s/.test(password);

export const isEmailValid = (email) =>
    /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email);

export const isNameValid = (name) =>
    /^[a-zA-ZéèêëàâäîïôöùûüçÉÈÊËÀÂÄÎÏÔÖÙÛÜÇ']+$/.test(name) &&
    name.trim().length >= NAME_MIN_LENGTH;

export const isDiscordValid = (discord) =>
    /^@[a-z0-9._]+$/.test(discord) &&
    !/\.\./.test(discord) &&
    discord.trim().length >= DISCORD_MIN_LENGTH;

export const isTeamNameValid = (teamName) =>
    teamName.trim().length >= NAME_MIN_LENGTH;

export const isCodeValid = (code) =>
    /^[a-z0-9]{4}$/.test(code.trim()) && !/\s/.test(code);

export const handleError = (message) => {
    Swal.fire({
        title: 'Erreur',
        text: message,
        icon: 'error',
        confirmButtonColor: '#F08000', // orange from tailwind.config.js
    });
};
