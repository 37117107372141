import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isEditing: false,
    email: '',
    firstName: '',
    lastName: '',
    discord: '',
    isRemote: false,
    isUnderage: false,
    meals: ['Je gère', 'Je gère', 'Je gère'],
    isPurchased: false,
    teamName: '',
    teamMembers: [''],
    teamInvitationCode: '',
};

export const accountSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setEmail: (state, action) => {
            state.email = action.payload;
        },
        setFirstName: (state, action) => {
            // update the team members by replacing my name with the new one
            state.teamMembers = state.teamMembers.map((member) =>
                member === state.firstName + ' ' + state.lastName
                    ? action.payload + ' ' + state.lastName
                    : member
            );
            state.firstName = action.payload;
        },
        setLastName: (state, action) => {
            // update the team members by replacing my name with the new one
            state.teamMembers = state.teamMembers.map((member) =>
                member === state.firstName + ' ' + state.lastName
                    ? state.firstName + ' ' + action.payload
                    : member
            );
            state.lastName = action.payload;
        },
        setDiscord: (state, action) => {
            state.discord = action.payload;
        },
        setIsRemote: (state, action) => {
            state.isRemote = action.payload;
        },
        setIsUnderage: (state, action) => {
            state.isUnderage = action.payload;
        },
        setMeals: (state, action) => {
            state.meals = action.payload;
        },
        setIsPurchased: (state, action) => {
            state.isPurchased = action.payload;
        },
        setTeamName: (state, action) => {
            state.teamName = action.payload;
        },
        setTeamMembers: (state, action) => {
            state.teamMembers = action.payload;
        },
        setTeamInvitationCode: (state, action) => {
            state.teamInvitationCode = action.payload;
        },
        setEditing: (state, action) => {
            state.isEditing = action.payload;
        },
        logoutUser: (state) => {
            // reset all the states to their initial values
            state.isEditing = initialState.isEditing;
            state.email = initialState.email;
            state.firstName = initialState.firstName;
            state.lastName = initialState.lastName;
            state.discord = initialState.discord;
            state.isRemote = initialState.isRemote;
            state.isUnderage = initialState.isUnderage;
            state.meals = initialState.meals;
            state.isPurchased = initialState.isPurchased;
            state.teamName = initialState.teamName;
            state.teamMembers = initialState.teamMembers;
            state.teamInvitationCode = initialState.teamInvitationCode;
        },
    },
});

export const {
    setEmail,
    setFirstName,
    setLastName,
    setDiscord,
    setIsRemote,
    setIsUnderage,
    setMeals,
    setIsPurchased,
    setTeamName,
    setTeamMembers,
    setTeamInvitationCode,
    setEditing,
    logoutUser,
} = accountSlice.actions;

export default accountSlice.reducer;
