import List from '../List';
import Section, { Subsection } from '../Section';
import RulesJSON from '../../json/rules.json';
import RoutesJSON from '../../json/routes.json';

const { rules, behavior } = RulesJSON;

const Rules = () => (
    <Section title={'Règlement de la LVLUP Game Jam'} id={RoutesJSON.rules}>
        <Subsection title={'Règles générales'} />
        <List points={rules} />
        <Subsection title={'Comportement'} />
        <List points={behavior} />
    </Section>
);

export default Rules;
