import { useSelector } from 'react-redux';

const Section = ({ children, title, id, buttons }) => {
    const sanitizedId = id?.startsWith('#') ? id.slice(1) : id;
    const banner = useSelector((state) => state.banner);

    return (
        <div
            className={
                id !== undefined
                    ? banner.enabled
                        ? '-mt-36 pt-36'
                        : '-mt-20 pt-20'
                    : ''
            }
            id={sanitizedId}
        >
            {/* to make the jump to id have an offset*/}
            <div className={'my-4'}>
                {/* items-center to center vertically */}
                <div className="flex items-center">
                    <h1 className={'text-2xl xl:text-3xl font-semibold mb-4'}>
                        {title}
                    </h1>
                    <div className="mb-4">{buttons}</div>
                </div>
                {children}
            </div>
        </div>
    );
};

export const Subsection = ({ children, title, buttons }) => (
    <div className={'my-8'}>
        <div className="flex items-center">
            <h2 className={'text-xl xl:text-2xl mb-3'}>{title}</h2>
            <div className="mb-3">{buttons}</div>
        </div>
        {children}
    </div>
);

export default Section;
