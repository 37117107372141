import Section from '../Section';
import RoutesJSON from '../../json/routes.json';
import ContactsJSON from '../../json/contacts.json';
import CustomLink from '../CustomLink';

const Contact = () => (
    <Section title={'Nous contacter'} id={RoutesJSON.contact}>
        <p>
            contacte-nous par email ici&nbsp;:{' '}
            <CustomLink
                link={`mailto:${ContactsJSON.email}`}
                hoverUnderline
                text={ContactsJSON.email}
            />{' '}
            si tu désires rejoindre la jam en tant que membre du comité, staff
            ou pour toute autre question.
        </p>
        <p>
            {' '}
            Alternativement, tu peux aussi nous contacter sur notre serveur{' '}
            <CustomLink
                link={ContactsJSON.discord}
                external
                hoverUnderline
                text={'Discord'}
            />{' '}
            dans le canal <span className={'italic'}>forum-questions</span>.
        </p>
    </Section>
);

export default Contact;
