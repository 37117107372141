import Section, { Subsection } from '../Section';
import RoutesJSON from '../../json/routes.json';
import ContactsJSON from '../../json/contacts.json';
import EditionJSON from '../../json/edition.json';
import List from '../List';
import CustomLink from '../CustomLink';

const About = () => (
    <Section
        title={'La plus grande game jam de Suisse !*'}
        id={RoutesJSON.about}
    >
        <Subsection title={"C'est quoi ?"}>
            <p className={'text-justify'}>
                Par équipe de 1 à 5 personnes, vous avez 48 heures pour créer un
                jeu vidéo ou un jeu de plateau. Le thème et les contraintes
                seront annoncés au début de la jam. Les jeux seront jugés par un
                jury de professionnels et les meilleurs remporteront des prix !
            </p>
        </Subsection>
        <Subsection title={"C'est où ?"}>
            <p>
                L'intégralité de la jam se déroule à la{' '}
                <CustomLink
                    link={'https://maps.app.goo.gl/DZxDnWYNq4G1t96G6'}
                    external
                    hoverUnderline
                    text={
                        "Haute École d'Ingénierie et d'Architecture de Fribourg"
                    }
                />
                .
            </p>
        </Subsection>
        <Subsection title={"Comment s'inscrire ?"}>
            <p>L'inscription se fait en trois étapes&nbsp;:</p>
            <List
                decimal={true}
                points={[
                    <span>
                        Remplir notre{' '}
                        <CustomLink
                            link={'https://forms.gle/iN1ER3FV2egKFLxQ9'}
                            hoverUnderline
                            text={"formulaire d'inscription"}
                            external
                        />{' '}
                        et payer les frais d'inscription (
                        <span className="font-semibold">
                            {EditionJSON.price}
                        </span>
                        , TWINT et cash acceptés), le premier jour de la jam
                    </span>,
                    <span>
                        Rejoindre notre serveur{' '}
                        <CustomLink
                            link={ContactsJSON.discord}
                            external
                            hoverUnderline
                            text={'Discord'}
                        />{' '}
                        pour être au courant des nouvelles et des communications
                        durant la jam
                    </span>,
                    <span>
                        Rejoindre notre jam sur{' '}
                        <CustomLink
                            link="https://itch.io/jam/lvlup-11"
                            external
                            hoverUnderline
                            text={'itch.io'}
                        />{' '}
                        pour soumettre votre jeu à la fin de la jam
                    </span>,
                ]}
            />
        </Subsection>
        <Subsection title={'Que fournit la game jam ?'}>
            <List
                points={[
                    <span>Les repas (à choisir lors de l'inscription)</span>,
                    <span>Un accès internet (câblé + Wi-Fi)</span>,
                    <span>
                        Des prises électriques (pensez à en prendre davantage si
                        vous avez beaucoup de matériel)
                    </span>,
                    <span>Des tables et des chaises</span>,
                    <span>
                        Des lieux de repos (sacs de couchage non inclus)
                    </span>,
                    <span>
                        Des salles isolées pour l'enregistrement audio
                        (microphones et enceintes non inclus)
                    </span>,
                    <span>
                        Pour les jeux de plateaux&nbsp;: la possibilité
                        d'imprimer les éléments de votre jeu (dans la limite du
                        possible)
                    </span>,
                ]}
            />
        </Subsection>
        <Subsection title={'Déroulement'}>
            <p>Vendredi&nbsp;:</p>
            <List
                points={[
                    <span>
                        {EditionJSON.date.hour}:00&nbsp;: Mise en place
                    </span>,
                    <span>18:00&nbsp;: cérémonie d'ouverture</span>,
                    <span>18:15&nbsp;: début de la jam</span>,
                ]}
            />
            <p>Samedi&nbsp;:</p>
            <List
                points={[
                    <span>07:30&nbsp;: petit-déjeuner</span>,
                    <span>12:00&nbsp;: dîner</span>,
                    <span>19:00&nbsp;: souper</span>,
                ]}
            />
            <p>Dimanche&nbsp;:</p>
            <List
                points={[
                    <span>07:30&nbsp;: petit-déjeuner</span>,
                    <span>12:00&nbsp;: dîner</span>,
                    <span>15:00&nbsp;: rendu des jeux</span>,
                    <span>18:15&nbsp;: cérémonie de clôture</span>,
                ]}
            />
        </Subsection>
        <Subsection title={'Remise des prix'}>
            <p>
                La LVLUP Game jam comporte un total de quatre prix (non
                cumulables par équipe)&nbsp;:
            </p>
            <List
                points={[
                    <span>
                        Prix <span className={'italic'}>Public</span>
                    </span>,
                    <span>
                        Prix <span className={'italic'}>Thème</span>
                    </span>,
                    <span>
                        Prix <span className={'italic'}>Contrainte</span> #1
                    </span>,
                    <span>
                        Prix <span className={'italic'}>Contrainte</span> #2
                    </span>,
                ]}
            />
        </Subsection>
    </Section>
);

export default About;
