import { Link } from 'react-router-dom';
import { FaArrowUpRightFromSquare } from 'react-icons/fa6';

const CustomLink = ({ link, hoverUnderline, external, title, text }) => {
    const linkClass = `${title ? 'text-black hover:text-blue' : 'text-blue'} ${
        hoverUnderline ? 'hover:underline' : ''
    }`;
    const linkTarget = external ? '_blank' : '_self';

    return (
        <Link to={link} target={linkTarget} className={linkClass}>
            {text}
            {external && <FaArrowUpRightFromSquare className="inline pl-1" />}
        </Link>
    );
};

export default CustomLink;
