import { NavHashLink } from 'react-router-hash-link';
import React, { useEffect, useState } from 'react';
import { RiArrowUpLine } from 'react-icons/ri';

const CustomButton = ({
    children,
    onClick,
    textSize,
    disabled = false,
    cancel = false,
}) => {
    return !disabled ? (
        <button
            className={`m-2 p-1 border rounded-md text-${textSize || 'base'}
                ${cancel ? 'border-red text-red' : 'border-orange text-orange'}
                ${
                    cancel
                        ? 'active:bg-red active:text-white hover:bg-red hover:text-white'
                        : 'active:bg-orange active:text-white hover:bg-orange hover:text-white'
                }
                `}
            onClick={onClick}
        >
            {children}
        </button>
    ) : (
        <button
            className={`m-2 p-1 border rounded-md border-gray-300 text-gray-300 text-${
                textSize || 'base'
            }`}
            onClick={onClick}
            disabled={disabled}
        >
            {children}
        </button>
    );
};

export const BackToTopButton = () => {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY >= window.innerHeight) {
                setIsVisible(true);
            } else {
                setIsVisible(false);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    if (!isVisible) return null;

    return (
        <button
            className={`m-2 p-1 border border-orange rounded-full text-orange
                active:bg-orange active:text-white
                hover:bg-orange hover:text-white
                fixed xl:bottom-4 bottom-0 xl:right-4 right-0`}
        >
            <NavHashLink smooth to={'#top'}>
                <RiArrowUpLine size={30} />
            </NavHashLink>
        </button>
    );
};

export default CustomButton;
