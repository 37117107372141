import {
    createBrowserRouter,
    redirect,
    RouterProvider,
} from 'react-router-dom';
import Home from '../pages/Home';
import Register from '../components/Register';
import RoutesJSON from '../json/routes.json';
import Error from '../pages/Error';
//import { isUserJWTValid } from '../tools/auth';
import Account from '../pages/Account';

const Router = () => {
    const handleRegistration = async () => {
        //const isJWTValid = await isUserJWTValid();
        //if (isJWTValid) return redirect(RoutesJSON.account);
        //else return null;
    };

    const router = createBrowserRouter([
        {
            path: RoutesJSON.home,
            element: <Home />,
            errorElement: <Error code={404} />,
        },
        /*
        {
            path: RoutesJSON.login,
            element: <Register isLogin={true} />,
            //loader: () => handleRegistration(),
        },
        {
            path: RoutesJSON.register,
            element: <Register isLogin={false} />,
            //loader: () => handleRegistration(),
        },
        {
            path: RoutesJSON.account,
            element: <Account />,
            //loader: () => isUserJWTValid(),
        },
        */
    ]);
    return <RouterProvider router={router} />;
};

export default Router;
