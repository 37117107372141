import Router from './routes';
import Banner from './components/Banner';

const App = () => (
    <div className={'font-poppins font-normal text-black sm:text-base text-sm'}>
        {/* high z-index to ensure it's always on top */}
        <div className={'sticky top-0 z-50'}>
            <Banner />
        </div>
        <Router />
    </div>
);

export default App;
