import { createSlice } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';

const ENABLED = '1';
const DISABLED = '0';
const KEY = 'showBanner';

const bannerCookie = Cookies.get(KEY);

const bannerSlice = createSlice({
    name: 'banner',
    initialState: {
        enabled: bannerCookie === ENABLED,
    },
    reducers: {
        disableBanner: (state) => {
            state.enabled = false;
            Cookies.set(KEY, DISABLED, { expires: 30 });
        },
        updateBanner: (state) => {
            if (
                bannerCookie === undefined ||
                (bannerCookie !== ENABLED && bannerCookie !== DISABLED)
            ) {
                state.enabled = true;
                Cookies.set(KEY, ENABLED, { expires: 30 });
            }
            state.enabled = Cookies.get(KEY) === ENABLED;
        },
    },
});

export const { enableBanner, disableBanner, updateBanner } =
    bannerSlice.actions;
export default bannerSlice.reducer;
